/*
 * @Author       : Easion
 * @Date         : 2022-03-13 00:12:19
 * @FilePath     : /official_website/src/router/index.js
 * @LastEditTime : 2022-03-13 16:53:59
 * @Description  : 
 */
import { createRouter, createWebHistory } from 'vue-router'
import PageView from '@/components/pageView/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: PageView,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/HomeView.vue')
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/ContactView.vue')
      },
      {
        path: 'agreement',
        name: 'profit',
        redirect: '/agreement/privacy',
        component: () => import('@/views/agreement/index.vue'),
        children: [
          {
            path: 'privacy',
            name: 'privacy',
            component: () => import('@/views/agreement/privacy.vue'),
          },
          {
            path: 'serivce',
            name: 'serivce',
            component: () => import('@/views/agreement/service.vue'),
          }
        ]
      }
    ]
  },
  { path: '/404', component: () => import('@/views/404View.vue'), },
  { path: '/:pathMatch(.*)', redirect: '/404', hidden: true, meta: { title: '404' } }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
