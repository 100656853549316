<!--
 * @Author       : Easion
 * @Date         : 2022-03-12 23:43:25
 * @FilePath     : /official_website/src/components/PageHead/index.vue
 * @LastEditTime : 2022-03-14 19:20:14
 * @Description  : 
-->
<template>
    <div class="header flex flex-item-center flex-content-between">
        <div class="logo flex flex-item-center flex-content-center">
            <!-- <img src="../../assets/logo.jpg" style="width:100%;height:100%;" /> -->
        </div>
        <div class="nav_view flex flex-item-center flex-content-end" v-if="!isMobile">
            <span
                @click="BtnEvent_nav_click(item.path)"
                class="item flex flex-content-center flex-item-center"
                :class="{ active: nacIndex == item.path }"
                v-for="(item, index) in navData"
                :key="index"
            >{{ item.title }}</span>
        </div>
        <div class="menu flex flex-content-center flex-item-center" v-else>
            <span class="menu_title" @click="showMenu = true">菜单</span>
            <div class="menu_view" :class="{ active: showMenu }">
                <ul class="menu_list">
                    <li
                        @click="BtnEvent_nav_click(item.path)"
                        class="item flex flex-item-center"
                        :class="{ active: nacIndex == item.path }"
                        v-for="(item, index) in navData"
                        :key="index"
                    >{{ item.title }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import { defineComponent } from "vue";
const { body } = document
const WIDTH = 992
export default defineComponent({
    name: 'PageHead',
    computed: {
        isMobile() {
            const rect = body.getBoundingClientRect()
            return rect.width - 1 < WIDTH
        },
    },
    data() {
        return {
            navData: [
                {
                    path: '/home',
                    title: "首页"
                },
                {
                    path: '/contact',
                    title: "联系我们"
                },
                {
                    path: '/agreement',
                    title: "法律信息"
                }
            ],
            nacIndex: "/",
            showMenu: false,
        }
    },
    methods: {
        BtnEvent_nav_click(path) {
            this.nacIndex = path;
            this.$router.push(path);
            this.showMenu = false;
        }
    },
    mounted() {
        let { path } = this.$route;
        path = path.split('/');
        this.BtnEvent_nav_click('/' + path[1]);
    },
})
</script>
.<style lang="scss" scoped>
.menu {
    position: relative;
    width: 72px;
    height: 72px;
    .menu_view {
        position: fixed;
        top: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.3);
        width: 100vw;
        height: 100vh;
        z-index: 9;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;
        .menu_list {
            margin: 0;
            padding: 0;
            width: 100px;
            height: 100%;
            background: #fff;
            position: absolute;
            left: 100%;
            top: 0;
            transition: all 0.3s;
            .item {
                padding: 10px;
                margin: 0;
                &.active {
                    color: #0052d9;
                }
            }
        }
        &.active {
            pointer-events: all;
            opacity: 1;
            .menu_list {
                left: calc(100% - 100px);
            }
        }
    }
}
.header {
    height: 72px;
    width: 100%;
    padding: 0 30px;
    font-size: 14px;
    position: relative;
    z-index: 99;
    .logo {
        width: 200px;
        height: 72px;
        background: #fff url(../../assets/logo.jpg) no-repeat center;
        background-size: cover;
    }
    .nav_view {
        width: 300px;
        height: 100%;
        .item {
            width: 100px;
            box-sizing: border-box;
            padding: 0 10px;
            height: 100%;
            position: relative;
            transition: all 0.3s;
            cursor: pointer;
            &::after {
                content: "";
                display: block;
                width: 0;
                height: 2px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate3d(-50%, 0, 0);
                transition: all 0.3s;
                background-color: #0052d9;
            }
            &.active {
                font-weight: bold;
                font-size: 16px;
                color: #0052d9;
                &::after {
                    width: 100%;
                }
            }
        }
    }
}
</style>