<!--
 * @Author       : Easion
 * @Date         : 2022-03-13 00:12:19
 * @FilePath     : /official_website/src/App.vue
 * @LastEditTime : 2022-03-13 12:33:10
 * @Description  : 
-->
<template>
  <router-view/>
</template>
<style lang="scss">
</style>
