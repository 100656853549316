/*
 * @Author       : Easion
 * @Date         : 2022-03-13 00:12:19
 * @FilePath     : /official_website/src/main.js
 * @LastEditTime : 2022-03-13 00:20:15
 * @Description  : 
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/index.scss'
createApp(App).use(store).use(router).mount('#app')
