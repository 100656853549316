<!--
 * @Author       : Easion
 * @Date         : 2022-03-13 00:12:19
 * @FilePath     : /official_website/src/components/pageView/index.vue
 * @LastEditTime : 2022-03-13 18:21:12
 * @Description  : 
-->
<template>
    <PageHead />
    <router-view :key="key" />
    <div
        class="pageBottom flex flex-item-center flex-content-center"
    >Copyright © 1998 - 2022 All Rights Reserved. 广州应龙数字科技有限公司 版权所有 粤ICP备2022022630号</div>
</template>
<script>
import PageHead from "@/components/PageHead"
export default {
    name: 'pageView',
    components: {
        PageHead
    },
    computed: {
        key() {
            return this.$route.path
        }
    }
}
</script>
<style lang="scss" scoped>
.pageBottom {
    background: #fbfbfb;
    padding: 30px;
    font-size: 12px;
    color: #bbb;
}
</style>
